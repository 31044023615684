import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment, useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Form, Input, Button, Select, Alert } from 'antd';
import 'antd/dist/antd.css';
import './ContactUsContent.css';
import axios from 'axios';

const { Option } = Select;

const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const tailLayout = {
    wrapperCol: {
      span: 16,
    },
  };
  
const ContactUsContent = () => {

    const [form] = Form.useForm();
    const [showMessage, setSHowMessage] = useState(false);

    const onFinish = (values) => {
        //console.log(values);
        let data = {
            subject:values['subject'],fullName:values['name'],email:values['email'],message:values['message']
        };
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/index4.php`,{data})
            .then(response => {
                //console.log(response.data);
                setSHowMessage(true);
                form.resetFields();
            })
            .catch(error => {
                console.log(error);
                //alert(JSON.stringify(error));
            })
      };

    const onSubjectChange = (value) => {
        switch (value) {
            case 'male':
            form.setFieldsValue({
                note: 'Hi, man!',
            });
            return;

            case 'female':
            form.setFieldsValue({
                note: 'Hi, lady!',
            });
            return;

            case 'other':
            form.setFieldsValue({
                note: 'Hi there!',
            });
        }
    };

    return (

        <Container className="text-start py-5">
            <Row>
                <Col lg={8} className="pe-5">
                    <h5 className="pb-4">E-customer service:</h5>
                    {showMessage ? 
                    <Alert message="We have recorded your message and someone will get back to you soon." type="success" />
                    : null}
                    <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} requiredMark={'optional'}>
                        <Form.Item
                            name="subject"
                            label="Subject"
                            rules={[
                            {
                                required: true, message: 'Subject is required'
                            },
                            ]}
                        >
                            <Select
                            placeholder=""
                            onChange={onSubjectChange}
                            allowClear
                            >
                            <Option value="Rebate Submission">Rebate Submission</Option>
                            <Option value="My Visa Rebate Reward Card">My Visa Rebate Reward Card</Option>
                            <Option value="My Rebate Status">My Rebate Status</Option>
                            <Option value="Tracking Number Request">Tracking Number Request</Option>
                            <Option value="My Virtual Visa Rebate">My Virtual Visa Rebate</Option>
                            <Option value="Update Information for my Rebate">Update Information for my Rebate</Option>
                            <Option value="SunPower Rebate Email not Received">SunPower Rebate Email not Received</Option>
                            <Option value="Rebate Submission Assistance">Rebate Submission Assistance</Option>
                            <Option value="SunPower Rebate Expired">SunPower Rebate Expired</Option>
                            <Option value="Rebate Registration">Rebate Registration</Option>
                            <Option value="Rebate Not Received">Rebate Not Received</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                            {
                                required: true, message: 'Full Name is required'
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email Address"
                            rules={[
                            {
                                required: true, type: 'email'
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="message"
                            label="Message"
                            rules={[
                            {
                                required: true, message: 'Message is required'
                            },
                            ]}
                        >
                            <Input.TextArea rows={5} />
                        </Form.Item>
                        <p>By clicking "SUBMIT," I consent to SunPower contacting me to provide information on my SunPower rebate. SunPower can call or text me (including SMS or MMS) (including via prerecorded messages and/or automated technology belonging to or hosted by third parties) at the telephone number I provided above even if it is on a state or Federal Do Not Call List. My consent is not a condition of purchase. Please see our <a href="https://global.sunpower.com/privacy-policy" target="_blank" className="blue-link">Privacy Statement</a> and <a href="https://global.sunpower.com/sunpower-terms-use-agreement" target="_blank" className="blue-link">Terms of Use</a>.</p>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit" className="btn-orange">
                            Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col lg={4} className="text-start border-start ps-5 d-none d-lg-block">
                    <h5 className="pb-4">Contact by phone:</h5>
                    <p>
                        800 - 409 - 8960
                    </p>
                    <p>
                    <h6 className="pb-3">Hours of Operations</h6>
                    </p>
                    <p>
                    Monday - Friday | 8:00 AM - 4:00 PM PST
                    </p>
                    
                    <h5 className="pb-4">Cardholder Services Contact Information</h5>
                    <p>1-844-725-8818</p>
                    <p><a href='bpsmyaccount.com' target='_blank'>bpsmyaccount.com </a></p>
                        
                </Col>
            </Row>
        </Container>
    );
}

export default ContactUsContent;